import axios from 'axios';
import { wixAxiosConfig } from '@wix/wix-axios-config';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';

import { isNewMembersAreaUrlEnabled } from '../../utils/experiments';
import { APP_TOKEN, MY_ACCOUNT_APP_DEF_ID, PROFILE_WIDGET_APP } from '../constants';
import { getCustomProfileBooleanSetting } from './custom-profile';

type MembersAreaAppApi = {
  refreshApp?(): Promise<void>;
};

type BooleanSettingsAPI = {
  setBooleanStyleParamForWidgets(key: string, value: boolean): Promise<void>;
};

const MEMBERS_AREA_API_URL = 'https://members.wixapps.net/members-area/_api';
const NEW_MEMBERS_AREA_API_URL = 'https://members.wixapps.net/_api/members-area/_api';

(async () => {
  const shouldUseNewMembersAreaUrl = await isNewMembersAreaUrlEnabled();

  if (shouldUseNewMembersAreaUrl) {
    return wixAxiosConfig(axios, { baseURL: NEW_MEMBERS_AREA_API_URL });
  }

  return wixAxiosConfig(axios, { baseURL: MEMBERS_AREA_API_URL });
})();

export const publishSettingsForMembersAreaApps = async (editorSDK: EditorSDK) => {
  const instance = await editorSDK.document.info.getAppInstance(APP_TOKEN);
  return axios.post('settings/publish', {}, { headers: { Authorization: instance } });
};

export const refreshMembersAreaApps = async (editorSDK: EditorSDK) => {
  const appsToRefresh = [MA_APP_IDS.ABOUT, MA_APP_IDS.FOLLOWERS, MA_APP_IDS.ALL_MEMBERS, PROFILE_WIDGET_APP];

  const appRefreshPromises = appsToRefresh.map(async ({ appDefinitionId }) => {
    const api: MembersAreaAppApi | void = await editorSDK.application.getPublicAPI(APP_TOKEN, { appDefinitionId });

    return api?.refreshApp?.();
  });

  await Promise.all(appRefreshPromises);
};

export const mergeGlobalSettings = async (editorSDK: EditorSDK, settings: Record<string, boolean>) => {
  const instance = await editorSDK.document.info.getAppInstance(APP_TOKEN);
  return axios.put('/settings/merge/global?viewMode=Editor', settings, { headers: { Authorization: instance } });
};

export const mergeComponentSettings = async (
  editorSDK: EditorSDK,
  settings: Record<string, boolean>,
  compId: string,
  appComponent: string,
) => {
  const instance = await editorSDK.document.info.getAppInstance(APP_TOKEN);
  return axios.put(
    `/settings/merge/component?appComponent=${appComponent}&compId=${compId}&viewMode=editor`,
    settings,
    {
      headers: { Authorization: instance },
    },
  );
};

export const setMyAccountBooleanParam = async (editorSDK: EditorSDK, param: { key: string; value: boolean }) => {
  const api = (await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: MY_ACCOUNT_APP_DEF_ID,
  })) as BooleanSettingsAPI | undefined;

  await api?.setBooleanStyleParamForWidgets(param.key, param.value);
};

export const setProfileCardBooleanParam = async (editorSDK: EditorSDK, param: { key: string; value: boolean }) => {
  const api = (await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: PROFILE_WIDGET_APP.appDefinitionId,
  })) as BooleanSettingsAPI | undefined;

  await api?.setBooleanStyleParamForWidgets(param.key, param.value);
};

export const setAboutPageBooleanParam = async (editorSDK: EditorSDK, param: { key: string; value: boolean }) => {
  const api = (await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: MA_APP_IDS.ABOUT.appDefinitionId,
  })) as BooleanSettingsAPI;

  const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId('', MA_APP_IDS.ABOUT.appDefinitionId);
  const aboutComponents = await editorSDK.tpa.app.getAllCompsByApplicationId('', applicationId);

  aboutComponents.forEach((comp) =>
    mergeComponentSettings(
      editorSDK,
      getCustomProfileBooleanSetting(true) as unknown as Record<string, boolean>,
      comp.id,
      MA_APP_IDS.ABOUT.pageId,
    ),
  );

  await api.setBooleanStyleParamForWidgets(param.key, param.value);
};
